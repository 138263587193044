<template>
  <div>
    <CModalExtended
      size="lg"
      :title="tituloModal"
      color="dark"
      :show.sync="VerPatio"
      :closeOnBackdrop="false"
    >
      <CRow>
        <CCol sm="12">
          <dataTableExtended
     class="align-center-row-datatable"
            :items="items"
            :fields="fields"
            :loading="Loading"
            column-filter
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            :items-per-page="tableText.itemsPerPage"
            hover
            small
            sorter
            pagination
          >
            <template #loading>
              <loading/>
            </template>
            <template #Status="{item}">
              <td class="text-center">
                <CBadge :color="getBadge(item.Status)">
                  {{ $t('label.'+item.Status) }}
                </CBadge>
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CRow>
      <template #footer>
          <CButton outline color="wipe" @click="VerPatio= false">
            <CIcon name="x"/>&nbsp;
            {{$t('button.exit')}}
          </CButton>
      </template>
    </CModalExtended>
  </div>  
</template>

<script>
import General from '@/_mixins/general';
import { DateFormater } from '@/_helpers/funciones';

function  fields() {
  return [
    { 
      key: 'Nro', 
      label: '#',
      _style: 'width:1%;',
      filter: false
    },
    { key: 'Sucursal', label:this.$t('label.branch'),_classes:"text-center" },
    { key: 'Patio', label:this.$t('label.yard'),_classes:"text-center" },
    { key: 'Usuario', label:this.$t('label.user'), _classes:"text-center" },
    { key: 'Fecha', label: this.$t('label.date'), _classes:"text-center" },
    { key: 'Status', label:this.$t('label.status'), _classes:"text-center" },
  ];
}

function data () {
  return {
    items: [],
    RoleId: '',
    Loading: false,
    VerPatio: false,
    tituloModal: '',
  }
}

//FUNCIONES DE METHOD
function filtroItems(listado) {
  this.items = listado
  .filter(listado => listado.FgActRoleConfig)
  .map(listado => Object.assign({}, this.items, {
    Nro: listado.Nro,
    Sucursal: listado.BranchName,
    Patio: listado.YardName ? listado.YardName:'N/A',
    Usuario: listado.TransaLogin ? listado.TransaLogin:'N/A',
    Fecha: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
    Status: listado.Status,
  }));

  return this.items;
}

function listarDatos () {
  this.Loading = true;
  let listado = Array;
  this.$http.get("Role-Config-by-role", { RoleId: this.RoleId })
  .then(response => {
    listado = [...response.data.data];
    this.filtroItems(listado);
    this.Loading= false;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
    this.Loading= false;
  });
}

function filtroVerPatios() {
    let i=1;
    return this.items.map((item) => Object.assign({}, item, {
      Fila: i++,
      _classes: item.Status === "INACTIVO" ? 'table-danger': ''
    }));
}

export default {
  name: 'rol-create',
  mixins: [General],
  data,
  props: {
    modal: null
  },
  methods: {
    listarDatos,
    filtroVerPatios,
    filtroItems
  },
  computed:{
    fields,
  },
  watch: {
    modal: function () {
      if (this.modal) {
        let _lang = this.$i18n.locale;
        let name =_lang =='en' ? this.modal.RoleNameEn : this.modal.RoleNameEs;

        this.VerPatio = true;
        this.RoleId = this.modal.RoleId;
        this.listarDatos();
        this.tituloModal=this.$t('label.branchByRoles')+": "+name;
        this.$emit('cerrarModal');
      }
    }
  }
}
</script>
<style scoped>

</style>