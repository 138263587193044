<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CreateRol :modal="vCrearRol" @cerrarModal="vCrearRol=false" @child-refresh="refrescarRol=true" />
    <VerPatio :modal="vVerPatio" @cerrarModal="vVerPatio=false" />
    <VisualizarMenu :modal="VerMenu" @cerrarModal="VerMenu=false" />
    <RolesByModule :ValorMRol="vCrearMRol" :NavigationFilter="NavigationFilter" />
    <SucursalModal :modal="sucModal" @cerrarModal="sucModal=false" />
    <CCard>
      <CCardHeader class="text-center bg-dark text-white">
        <b>{{$t('label.moduleRoleList')}}</b>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CCard style="border: none;">
              <CCardHeader color="dark" text-color="white">
                <h5>{{$t('label.filter')}}</h5>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol sm="12" lg="4">
                    <CSelect
                        :label="$t('label.subsystemType')"
                        :horizontal="{label:'col-sm-12 col-lg-5',input:'col-sm-12 col-lg-5'}"
                        addLabelClasses="text-right"
                        :options="subsystemTypeListFormatted"
                        :value="TpModuleFilter"
                        @change="SubSystemList($event)"                
                      
                    />
                  </CCol>
                  <CCol sm="12" lg="3" v-if="this.TpModuleFilter.toUpperCase() === this.sigetWebId.toUpperCase()">
                    <CSelect                
                        :label="$t('label.navigation')"
                        :horizontal="{label:'col-sm-12 col-lg-5',input:'col-sm-12 col-lg-6'}"
                        addLabelClasses="text-right"
                        :options="navegationListFormatted"
                        :value="NavigationFilter"
                        @change="NavigationList($event)"
                    />
                  </CCol>
                  <CCol sm="12" lg="3"  v-if="NavigationFilter.toUpperCase() === this.visitaId.toUpperCase()">
                    <CSelect                
                        :label="$t('label.vesselName')"
                        :horizontal="{label:'col-sm-12 col-lg-5',input:'col-sm-12 col-lg-6'}"
                        addLabelClasses="text-right"
                        :options="vesselListFormatted"
                        :v-model="VesselSelect"
                        :value="VesselSelect"
                        @change="VesselList($event)"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12" lg="4" v-if="NavigationFilter.toUpperCase() !== this.visitaId.toUpperCase()">
                    <CSelect
                      :label="$t('label.group')+' '+$t('label.level')+' 1'"
                      :placeholder="$t('label.select')"
                      :horizontal="{label:'col-sm-12 col-lg-5',input:'col-sm-12 col-lg-5'}"
                      addLabelClasses="text-right"
                      :options="grupo1Computed"
                      :v-model="grupo1Filter"
                      :value="grupo1Filter"
                      @change="listarGrupo2($event)"
                    />
                  </CCol>
                  <CCol sm="12" lg="3" v-if="NavigationFilter.toUpperCase() !== this.visitaId.toUpperCase()">
                    <CSelect
                      :label="$t('label.group')+' '+$t('label.level')+' 2'"
                      :placeholder="$t('label.select')"
                      :horizontal="{label:'col-sm-12 col-lg-5',input:'col-sm-12 col-lg-6'}"
                      addLabelClasses="text-right"
                      :options="grupo2Computed"
                      :v-model="grupo2Filter"
                      :value="grupo2Filter"
                      @change="listarModulos($event)"
                    />
                  </CCol>
                  <CCol sm="12" lg="3" v-if="(this.TpModuleFilter.toUpperCase() === this.sigetWebId.toUpperCase()) && ((this.NavigationFilter.toUpperCase() && this.NavigationFilter.toUpperCase()!=this.visitaId.toUpperCase()))">
                    <CSelect
                      :label="$t('label.view')+' MENU'"
                      :placeholder="$t('label.select')+$t('label.role')"
                      :horizontal="{label:'col-sm-12 col-lg-5',input:'col-sm-12 col-lg-6'}"
                      addLabelClasses="text-right"
                      :options="rolAll"
                      v-model="rolActual"
                      :value.sync="rolActual"
                    />
                  </CCol>
                  <CCol sm="12" lg="2" class="text-left" v-if="(this.TpModuleFilter.toUpperCase() === this.sigetWebId.toUpperCase()) && ((this.NavigationFilter.toUpperCase() && this.NavigationFilter.toUpperCase()!=this.visitaId.toUpperCase()))">
                    <CButton color="watch" @click="previsualizar" v-c-tooltip="{placement:'top-end',content: $t('label.menu') + ' ' + $t('label.role')}" class="ml-2">                      
                      <CIcon name="eye" />&nbsp; {{$t('label.preview')}}
                    </CButton>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader color="secondary" text-color="white">
                <h5 class="float-left">{{$t('label.moduleList')}}</h5>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol sm="12">
                    <dataTableExtended
     class="align-center-row-datatable"
                      :items="itemsModulo"
                      :fields="fieldsModulo"
                      column-filter
                      :table-filter="tableText.tableFilterText"
                      :items-per-page-select="tableText.itemsPerPageText"
                      :noItemsView="tableText.noItemsViewText"
                      :items-per-page="tableText.itemsPerPage"
                      hover
                      small
                      sorter
                      pagination
                    >
                      <template #ModuleId-header>
                        <input
                          type="checkbox"
                          v-model="selectedAllModulo"
                          @click="selectAllModulos"
                        />
                         <!-- <CCol sm="12" class="px-0 ">
                            <div role="group" class="checkbook-filter text-left custom-control custom-checkbox mt-2">
                              <input 
                                id="module_all" 
                                type="checkbox" 
                                class="custom-control-input"
                                v-model="selectedAllModulo"
                                @click="selectAllModulos" 
                              >
                              <label for="module_all" class="custom-control-label"></label>
                            </div>
                          </CCol> -->
                      </template>
                      <template #ModuleId="{item}">
                        <td>
                          <input
                            type="checkbox"
                            v-model="selectedModulo"
                            :value="item.ModuleId"
                          />
                          <!-- <CCol sm="12" class="px-0 ">
                            <div role="group" class="checkbook-filter text-left custom-control custom-checkbox mt-2">
                              <input 
                                id="module_select" 
                                type="checkbox" 
                                class="custom-control-input"
                                v-model="selectedModulo"
                                :value="item.ModuleId" 
                              >
                              <label for="module_select" class="custom-control-label"></label>
                            </div>
                          </CCol> -->
                        </td>
                      </template>
                      <template #Detalle="{item}">
                        <td class="center-cell">
                          <CButton
                            color="edit"
                            square
                            size="sm"
                            v-c-tooltip="{placement:'top-end',content: $t('label.edit')+$t('label.permission')}"
                            @click="abrirMrol(item)"
                          >
                            <CIcon name="pencil" />
                          </CButton>
                        </td>
                      </template>
                    </dataTableExtended>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol sm="6" class="border-right">
            <CCard>
              <CCardHeader color="danger" text-color="white">
                <h5 class="float-left">{{$t('label.generalRoleList')}}</h5>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol sm="12" class="d-flex justify-content-end">
                      <div>
                          <CButton
                            color="add"
                            class="float-right"
                            @click="vCrearRol=true"
                          >
                            <CIcon name="cil-playlist-add"/>&nbsp; {{$t('label.nuevo')+''+$t('label.generalRole')}}
                          </CButton>
                      </div>
                  </CCol>                    
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <dataTableExtended
     class="align-center-row-datatable"
                      :items="itemsRolGeneral"
                      :fields="fieldsRol"
                      column-filter
                      :table-filter="tableText.tableFilterText"
                      :items-per-page-select="tableText.itemsPerPageText"
                      :noItemsView="tableText.noItemsViewText"
                      :loading="LoadingRol"
                      :items-per-page="tableText.itemsPerPage"
                      hover
                      small
                      sorter
                       
                      pagination
                    >
                      <template #loading>
                        <loading/>
                      </template>
                      <template #RoleId-header>
                        <input
                          type="checkbox"
                          v-model="selectedAllRolGeneral"
                          @click="selectAllRolGeneral"
                        />
                      </template>
                      <template #RoleId="{item}">
                        <td style='width:1%;'>
                          <input
                            type="checkbox"
                            v-model="selectedRolGeneral"
                            :value="item.RoleId"
                          />
                        </td>
                      </template>
                      <template #Detalle="{item}">
                        <td class="center-cell">
                          <CButton
                            color="watch"
                            square
                            size="sm"
                            v-c-tooltip="{placement:'top-end',content:$t('label.toView') + ' ' + $t('label.role')}"
                            @click="vVerPatio=item"
                          >
                            <CIcon name="eye" />
                          </CButton>
                        </td>
                      </template>
                    </dataTableExtended>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol sm="6">
            <CCard>
              <CCardHeader color="warning" text-color="white">
                <h5 class="float-left">{{$t('label.specificRoleList')}}</h5>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <CCol sm="12" class="d-flex justify-content-end">
                      <div>
                          <CButton
                            color="add"
                            class="float-right mr-2"
                            @click="abrirSucursal(true)"
                          >
                            <CIcon name="cil-playlist-add"/>&nbsp; {{$t('label.nueva')+''+$t('label.branch')}}
                          </CButton>
                      </div>
                      <div>
                          <CButton
                            color="add"
                            class="float-right mr-0"
                            @click="abrirRol(true)"
                          >
                            <CIcon name="cil-playlist-add"/>&nbsp; {{$t('label.nuevo')+''+$t('label.specificRole')}}
                          </CButton>
                      </div>
                  </CCol>                    
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <dataTableExtended
     class="align-center-row-datatable"
                      :items="itemsRolEspecifico"
                      :fields="fieldsRolEspecifico"
                       column-filter
                      :table-filter="tableText.tableFilterText"
                      :items-per-page-select="tableText.itemsPerPageText"
                      :noItemsView="tableText.noItemsViewText"
                      :loading="LoadingRol"
                      :items-per-page="tableText.itemsPerPage"
                      hover
                      small
                      sorter
                       
                      pagination
                    >
                      <template #loading>
                        <loading/>
                      </template>
                      <template #RoleId-header>
                        <input
                          type="checkbox"
                          v-model="selectedAllRolEspecifico"
                          @click="selectAllRolEspecifico"
                        />
                      </template>
                      <template #RoleId="{item}">
                        <td>
                          <input
                            type="checkbox"
                            v-model="selectedRolEspecifico"
                            :value="item.RoleId"
                          />
                        </td>
                      </template>
                      <template #Detalle="{item}">
                        <td class="center-cell">
                          <CButton
                            color="watch"
                            square
                            size="sm"
                            v-c-tooltip="{placement:'top-end',content: $t('label.toView') + ' ' + $t('label.role')}"
                            @click="vVerPatio=item"
                          >
                            <CIcon name="eye" />
                          </CButton>
                        </td>
                      </template>
                    </dataTableExtended>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol sm="12" class="text-right">
            <CButton color="add" :disabled="isDisabled" @click="guardar">
              <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import CreateRol from '@/pages/rol/rol-modal';
import VerPatio from '@/pages/rol/rol-ver-patio';
import SucursalModal from '@/pages/empresa/sucursal-modal';
import RolesByModule from './roles-by-module';
import VisualizarMenu from './visualizar-menu';
import { mapState } from 'vuex';
import GeneralMixin from '@/_mixins/general';

//DATA
function data() {
  return {
    UserId: '',
    itemsRolGeneral: [],
    itemsRolEspecifico: [],
    rolAll: [],
    itemsModulo: [],
    error: null,
    selectedModulo: [],
    selectedAllModulo: false,
    selectedRolGeneral: [],
    selectedAllRolGeneral: false,
    selectedRolEspecifico: [],
    selectedAllRolEspecifico: false,
    grupo1: [],
    grupo2: [],
    empresaActual: [],
    grupoTodos: [],
    vCrearRol: false,
    vCrearMRol: [],
    LoadingRol: false,
    Loading: false,
    refrescarRol: false,
    vVerPatio: false,
    sucModal: false,
    VerMenu: false,
    rolActual: '',
    AddModulo: false,
    Group1Obj: [],
    Group2Obj: [],
    TpModuleFilter:'',
    NavigationFilter:'',
    sudSystemItems:[],
    navigationItems:[],
    vesselItems:[],
    grupo1Filter:'',
    grupo2Filter:'',
    VesselSelect:'',
    sigetWebId:'886B95BE-452A-45E0-8C11-2C162BC31AFE',  
    visitaId:'5FFE2FBD-CD0C-4B78-96B7-0C1F0F8CB97C',
  };
}
    
function abrirSucursal(item) {
  this.sucModal = {Item: item, Empresas: this.empresaActual };
}

function selectAllModulos() {
  this.selectedModulo = [];

  if (!this.selectedAllModulo) {
    this.itemsModulo.forEach((ob) => {
      this.selectedModulo.push(ob.ModuleId);
    });
  }
}

function selectModulos() {
  this.selectedAllModulo = false;
}

function selectAllRolGeneral() {
  this.selectedRolGeneral = [];

  if (!this.selectedAllRolGeneral) {
    this.itemsRolGeneral.forEach((ob) => {
      this.selectedRolGeneral.push(ob.RoleId);
    });
  }
}

function selectRolGeneral() {
  this.selectedAllRolGeneral = false;
}

function selectAllRolEspecifico() {
  this.selectedRolEspecifico = [];

  if (!this.selectedAllRolEspecifico) {
    this.itemsRolEspecifico.forEach((ob) => {
      this.selectedRolEspecifico.push(ob.RoleId);
    });
  }
}

function selectRolEspecifico() {
  this.selectedAllRolEspecifico = false;
}
function traeEmpresa() {
  let listado=[];
  this.$http.get("Company-by-id", { CompanyId: this.user.CompanyId })
    .then((response) => {
      listado = [...response.data.data];
      this.empresaActual = listado;
    })
    .catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: 'error',
      });
    });
}

function listarGrupo1() {
  let listado = [];
  this.grupo2 = [];
  this.itemsModulo = [];
  this.grupo2Filter = '';
  this.Loading = true;
  this.$http.get("GpoModule-list",{TpModuleId: this.TpModuleFilter,TpModuleNavegationId:this.NavigationFilter, GpoModuleOrigId:this.grupo1Filter})
    .then((response) => {
      listado = [...response.data.data];
      this.grupoTodos = listado;
      this.grupo1 = listado;
      this.grupo1Filter = this.grupo1[0].GpoModuleId;
      this.listarGrupo2();
      this.Loading = false
      //.sort();
    })
    .catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: 'error',
      });
      this.Loading = false;
    });
}
function listarGrupo2(event) {
  this.grupo1Filter = event ? event.target.value : this.grupo1Filter;
  this.itemsModulo = [];
  this.grupo2Filter = '';
  let Datagrupo2 = this.grupoTodos.filter( (item) => {return item.GpoModuleId === this.grupo1Filter && item.GpoModuleChildJson.length > 0 });
  if (Datagrupo2.length!=0) {
    this.grupo2 = Datagrupo2[0].GpoModuleChildJson;
    this.grupo2Filter = this.grupo2[0].GpoModuleId;
    this.listarModulos();
  }else{
    this.grupo2 = [];
  }
}

function listarRoles() {
  this.itemsRolGeneral = [];
  this.itemsRolGeneral.length = 0;
  this.itemsRolEspecifico = [];
  this.itemsRolEspecifico.length = 0;
  this.rollAll = [];
  this.rollAll.length = 0;
  this.LoadingRol = true;
  let TempGeneral = [];
  let TempEspecifico = [];
  let _lang = this.$i18n.locale;
  let listado = Array;
  this.$http.get("Role-list", { Filter: 'ACTIVO' })
    .then((response) => {
      listado = [...response.data.data];
      
      listado.forEach((ob) => {
        this.rolAll.push({
          value: ob.RoleId,
          label: _lang=='en' ? ob.RoleNameEn : ob.RoleNameEs,
        });
        if (ob.FgGeneral) {
          TempGeneral.push({
            RoleId: ob.RoleId,
            RoleNameEs: ob.RoleNameEs,
            RoleNameEn: ob.RoleNameEn,
          });
        } else {
          TempEspecifico.push({
            RoleId: ob.RoleId,
            RoleNameEs: ob.RoleNameEs,
            RoleNameEn: ob.RoleNameEn,
          });
        }
      });

      this.itemsRolGeneral = TempGeneral;
      this.itemsRolEspecifico = TempEspecifico;

      this.LoadingRol = false;
    })
    .catch((err) => {
      this.LoadingRol = false;
    });
}

function listarModulos(event) {
  this.itemsModulo = [];
  this.selectedModulo = [];
  this.selectedModulo.length = 0;
  this.Loading = true;
  this.rollAll = [];
  event ? this.grupo2Filter = event.target.value : '';
  let listado = [];
  if ((this.grupo2Filter!='' )) {
    this.$http.get("GpoModuleGeneral-list", { TpModuleId: this.TpModuleFilter,TpModuleNavegationId:this.NavigationFilter,TpVesselId:this.VesselSelect,GpoModuleId:this.grupo2Filter})
    .then((response) => {
      listado = [...response.data.data];
       this.itemsModulo = listado
        .map((listado) =>
          Object.assign({}, this.items, {
            ModuleId: listado.ModuleId,
            ModuleNameEs: listado.ModuleNameEs,
            ModuleNameEn: listado.ModuleNameEn,
            ModuleDs: listado.ModuleDs,
            ProcessRoute: listado.ProcessRoute,
          })
        )
        .sort();
      this.Loading = false;
    })
    .catch((err) => {
      this.Loading = false;
    });
  }else{
    this.itemsModulo = [];
    this.Loading = false;
  }
}
function listarModulosVisit() {
  this.itemsModulo = [];
  this.selectedModulo = [];
  this.selectedModulo.length = 0;
  this.Loading = true;
  let listado = [];
  if ((this.VesselSelect!='' )) {
    this.$http.get("GpoModuleGeneral-list", { TpModuleId: this.TpModuleFilter,TpModuleNavegationId:this.NavigationFilter,TpVesselId:this.VesselSelect,GpoModuleId:this.grupo2Filter})
    .then((response) => {
      listado = [...response.data.data];
       this.itemsModulo = listado
        .map((listado) =>
          Object.assign({}, this.items, {
            ModuleId: listado.ModuleId,
            ModuleNameEs: listado.ModuleNameEs,
            ModuleNameEn: listado.ModuleNameEn,
            ModuleDs: listado.ModuleDs,
            ProcessRoute: listado.ProcessRoute,
          })
        )
        .sort();
      this.Loading = false;
    })
    .catch((err) => {
      this.Loading = false;
    });
  }else{
    this.itemsModulo = [];
    this.Loading = false;
  }
}



function abrirMrol(opt) {
  if (!opt) {
    this.vCrearMRol = opt;
  } else {
    this.vCrearMRol = {
      ModuleId: opt.ModuleId,
      NombreModuloEs: opt.ModuleNameEs,
      NombreModuloEn: opt.ModuleNameEn,
      Roles: this.rolAll,
    };
  }
}

function abrirRol(opt) {
  this.vCrearRol = {
    esGeneral: opt,
  };
}

function refrescar() {
  this.refrescarRol = true;
}

function guardar() {
  if (
    this.selectedModulo < 1 ||
    this.selectedRolEspecifico.length + this.selectedRolGeneral.length < 1
  )
    return false;
  let datos = [];
  let ModuleRoleJson = [];
  let i;
  let tope;
  let res = [];
  this.Loading = true;
  tope = Math.max(
    this.selectedRolEspecifico.length,
    this.selectedRolGeneral.length
  );

  this.selectedModulo.forEach((mod) => {
    for (i = 0; i < tope; i++) {
      if (i < this.selectedRolGeneral.length) {
        datos = {
          TpModuleNavegationId: this.NavigationFilter,
          ModuleId: mod,
          RoleId: this.selectedRolGeneral[i],
          UserId: this.user.UserId,
        };

        ModuleRoleJson.push(datos);
      }
      if (i < this.selectedRolEspecifico.length) {
        datos = {
          TpModuleNavegationId: this.NavigationFilter,
          ModuleId: mod,
          RoleId: this.selectedRolEspecifico[i],
          UserId: this.user.UserId,
        };

        ModuleRoleJson.push(datos);
      }
    }
  });

  this.$http.post("ModuleRole-insert", ModuleRoleJson, { root: 'ModuleRoleJson' })
    .then((response) => {
      res = [...response.data.data];
      this.cleanDataCheck();
      this.$notify({
        group: 'container',
        title: '¡Exito!',
        text: res[0].Response,
        type: 'success',
      });
      this.Loading = false;
    })
    .catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: 'error',
      });
      this.Loading = false;
    });
}

function previsualizar() {
  if (this.rolActual!=='') {
    let rol = this.rolAll.filter(rol => rol.value == this.rolActual);
    this.VerMenu = rol;
  }
}

function getSudSystemList() {
    this.Loading = true;
    this.$http.get("TpModuleConfigWeb-list")
    .then(response => {       
      this.sudSystemItems = response.data.data;
      this.TpModuleFilter = this.sudSystemItems[0].TpModuleId; 
      this.listarGrupo1();
      this.Loading = false; 
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.Loading = false; 
    });
} 
function SubSystemList(event) {
  this.TpModuleFilter = event.target.value; 
  this.cleanData();
  if (this.TpModuleFilter!='') {
    if (this.TpModuleFilter.toUpperCase() === this.sigetWebId) {
      let NavigationWeb = this.sudSystemItems.find((val) => val.TpModuleId.toUpperCase() == this.sigetWebId);
      this.navigationItems = NavigationWeb.TpModuleNavegationJson;
      this.NavigationFilter = this.navigationItems[0].TpModuleNavegationId;
      this.NavigationList();
    }else{
      this.listarGrupo1();
    }
  }
}

function NavigationList(event) {
  event ? this.NavigationFilter = event.target.value : '';
  this.itemsModulo = [];
  this.grupo1 = [];
  this.grupo2 = [];
  this.vesselItems = [];
  this.VesselSelect = '';
  this.grupo1Filter = '';
  this.grupo2Filter = '';
  if (this.NavigationFilter!='') {
    if (this.NavigationFilter==this.visitaId) {
      this.getVesselList();
    } else {
      this.listarGrupo1();
    }
    
  } else {
    this.itemsModulo = [];
    this.grupo1 = [];
    this.grupo2 = [];
    this.vesselItems = [];
    this.VesselSelect = '';
    this.grupo1Filter = '';
    this.grupo2Filter = '';
  }
}
function getVesselList() {
  this.Loading = true;
  this.$http.get("TpVessel-list")
    .then(response => {       
      this.vesselItems = response.data.data;
      this.VesselSelect = this.vesselItems[0].TpVesselId;
      this.listarModulosVisit();
      this.Loading = false;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.Loading = false
    });
} 
function VesselList(event) {
  event ? this.VesselSelect = event.target.value : '';
  this.grupo1 = [];
  this.grupo2 = [];
  this.grupo1Filter = '';
  this.grupo2Filter = '';
  if (this.TpModuleNavegationId!='') {''
    if (this.VesselSelect!='') {
      this.listarModulosVisit();
    }else{
      this.itemsModulo = [];
      this.grupo1 = [];
      this.grupo2 = [];
      this.grupo1Filter = '';
      this.grupo2Filter = '';
    }
  }
}

function cleanData(){
  this.itemsModulo = [];
  this.grupo1 = [];
  this.grupo2 = [];
  this.navigationItems = [];
  this.NavigationFilter = '';
  this.vesselItems = [];
  this.VesselSelect = '';
  this.grupo1Filter = '';
  this.grupo2Filter = '';
}
function cleanDataCheck(){
  this.selectedModulo = [];
  this.selectedAllModulo = false;
  this.selectedRolGeneral = [];
  this.selectedAllRolGeneral =false;
  this.selectedRolEspecifico = [];
  this.selectedAllRolEspecifico = false;
}


//COMPUTED
function isDisabled() {
  return (
    this.selectedModulo < 1 ||
    this.selectedRolEspecifico.length + this.selectedRolGeneral.length < 1
  );
}
function subsystemTypeListFormatted(){
  let _lang = this.$i18n.locale;
  if(this.sudSystemItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.sudSystemItems.map(function(e){
      chart.push({
        value: e.TpModuleId, 
        label: _lang=='en' ? e.TpModuleNameEn : e.TpModuleNameEs,
      })    
    })
    return chart;
  }
} 

function navegationListFormatted(){
  let _lang = this.$i18n.locale;
  if(this.navigationItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.navigationItems.map(function(e){
      chart.push({
        value: e.TpModuleNavegationId, 
        label: _lang=='en' ? e.TpModuleNavegationEn : e.TpModuleNavegationEs,
      })    
    })
    return chart;
  }
}   
function grupo1Computed(){
  let _lang = this.$i18n.locale;
  if(this.grupo1.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.grupo1.map(function(e){
      chart.push({
        value: e.GpoModuleId, 
        label: _lang=='en' ? e.GpoModuleNameEn : e.GpoModuleNameEs,
      })    
    })
    return chart;
  }
}  
function grupo2Computed(){
  let _lang = this.$i18n.locale;
  if(this.grupo2.length==0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.grupo2.map((item)=>{
      chart.push({
        value: item.GpoModuleId, 
        label: _lang=='en' ? item.GpoModuleNameEn : item.GpoModuleNameEs,
      })    
    })
    return chart;
  }
}  
function vesselListFormatted(){
  if(this.vesselItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.vesselItems.map(function(e){
      chart.push({
        value: e.TpVesselId, 
        label: e.TpVesselName,
      })    
    })
    return chart;
  }
}  

function fieldsRol(){
  return[
          { key: 'RoleId', label: 'Sel', sorter: false, filter: false, _style:'width:1%;min-width:5px;',},
          { key: 'RoleNameEs', label: this.$t('label.name')+' '+('(ES)'),_style:'width:400px;',_classes:'text-center'},
          { key: 'RoleNameEn', label: this.$t('label.name')+' '+('(EN)'),_style:'width:400px;',_classes:'text-center'},
          { key: 'Detalle', label: '',_style:'width:50px;',sorter: false, filter: false,},
        ]
}

function fieldsRolEspecifico(){
  return[
          { key: 'RoleId', label: 'Sel', sorter: false, filter: false,_style:'width:1%;min-width:5px;',},
          { key: 'RoleNameEs', label: this.$t('label.name')+' '+('(EN)'),_style:'width:400px;',_classes:'text-center' },
          { key: 'RoleNameEn', label: this.$t('label.name')+' '+('(EN)'),_style:'width:400px;',_classes:'text-center' },
          { key: 'Detalle', label: '', sorter: false, filter: false,_style:'width:50px;',},
        ]
}

function fieldsModulo(){
  return[
          { key: 'ModuleId', label: 'Sel', sorter: false, filter: false, _style:'width:1%;min-width:5px;',},
          { key: 'ModuleNameEs', label: this.$t('label.module')+' '+('(ES)'),_style:'width:30%;',_classes:'text-center center cell'},
          { key: 'ModuleNameEn', label: this.$t('label.module')+' '+('(EN)'),_style:'width:30%;',_classes:'text-center center cell'},
          { key: 'ModuleDs', label: this.$t('label.description'),_style:'width:30%;',_classes:'text-center center cell'},
          //{ key: 'ProcessRoute', label: this.$t('label.route'),_style:'width:400px;',_classes:'text-center center cell' },
          { key: 'Detalle', label: '', _style:'width:50px;', sorter: false, filter: false,},
        ]
}  

export default {
  name: 'index',
  data,
  mixins: [GeneralMixin],
  components: {
    CreateRol,
    VerPatio,
    RolesByModule,
    SucursalModal,
    VisualizarMenu,
  },
  methods: {
    refrescar,
    listarRoles,
    listarGrupo1,
    listarGrupo2,
    listarModulos,
    listarModulosVisit,
    selectAllModulos,
    selectModulos,
    selectAllRolGeneral,
    selectRolGeneral,
    selectAllRolEspecifico,
    selectRolEspecifico,
    guardar,
    abrirMrol,
    abrirSucursal,
    traeEmpresa,
    abrirRol,
    previsualizar,
    getSudSystemList,
    SubSystemList,
    NavigationList,
    getVesselList,
    VesselList,
    cleanData,
    cleanDataCheck
  },
  computed: {
    isDisabled,
    fieldsRol,
    fieldsRolEspecifico,
    fieldsModulo,
    subsystemTypeListFormatted,
    navegationListFormatted,
    grupo1Computed,
    grupo2Computed,
    vesselListFormatted,
    ...mapState({
        user: state => state.auth.user,
    })
  },    
  watch: {
    refrescarRol: function() {
      if (this.refrescarRol) {
        this.listarRoles();
        this.refrescarRol = false;
      }
    },
  },
  mounted() {
    this.getSudSystemList(),
    this.traeEmpresa();
    this.listarRoles();
  },  
};
</script>

<style lang="scss">
.img-index-imdg {
  width: 50px;
  height: 50px;
}
.center-cell {
  text-align: center;
}
 
</style>